import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Theme from '../../components/layout/theme';
import { useForm, Controller } from "react-hook-form";
import api from '../../services/api';
import { Alert, AlertTitle } from '@material-ui/lab';
import SendButton from "../../components/form/send-button";
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import GeneralErrors from '../../components/form/general-errors';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://www.criaredavinci.com.br/">
        Criare Da Vinci
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function RecoveryRequestPage() {
  const classes = useStyles();

  const { control, handleSubmit } = useForm();
  const [errors, setErrors] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const onSubmit = async data => {
    setErrors(false);
    setLoading(true);

    try {
      await api.post(`/auth/recovery`, data);
      setSuccess(true);
    } catch (e) {
      setErrors(e?.response?.data || 'Erro desconhecido');
    }

    setLoading(false);
  };

  return (
    <Theme>
      <Container component="main" maxWidth="xs">
        <div className={classes.paper}>
          <img src={`/images/logo_criare_black.svg`} alt="Logo Criare" height={100} />
          <form className={classes.form} onSubmit={handleSubmit(onSubmit)} method="post">
            <Controller
              name="email"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField {...field}
                  label="E-mail"
                  type="email"
                  autoComplete="email"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  autoFocus
                />
              )}
            />

            {errors && <Alert severity="error"><GeneralErrors errors={errors} /></Alert>}
            {success && (
              <Alert severity="success">
                <AlertTitle>Sucesso</AlertTitle>
                Enviamos para o seu e-mail um link para que você altere a senha.
              </Alert>
            )}

            {!success && (
              <SendButton
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                loading={loading}
              >
                Enviar e-mail para recuperar a senha
              </SendButton>
            )}
            <Grid container>
              <Grid item xs>
                <Link href="/login" variant="body2">
                  <NavigateBeforeIcon /> Login
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    </Theme>
  );

}